.card {
	padding: 0.5em 1em;
	margin: 0.25em 0.5em;

	background-color: var(--background-color-3);
	border-radius: 0.5em;
	box-shadow: 0 0 0.2em var(--background-color-0);
	transition: all 0.2s ease-in-out;

	display: flex;
	flex-direction: column;
}

.card[data-orientation='horizontal'] {
	min-height: 140px;
}

.card[data-orientation='vertical'] {
	min-width: 250px;
	min-height: 300px;
}

.card:hover[data-hover='true'] {
	background-color: var(--background-color-4);
	scale: 1.025;
	border-radius: 0.2em;
}

.title {
	font-size: 1.1em;
	font-weight: bold;
	width: 100%;
	margin: 0;
}

.hint {
	font-size: 0.8em;
	width: 100%;
	margin: 0;
}

.card[data-big='true'] .content {
	font-size: 2em;
	text-align: center;
	margin-top: 35%;
}

.icon {
	width: 100%;
	height: 100%;
	margin: 0.5em 0.3em;
}

.hint[data-float='left'],
.content[data-float='left'] {
	text-align: left;
}

.hint[data-float='right'],
.content[data-float='right'] {
	text-align: right;
}

.button {
	float: right;
	margin: 0.5em 0;
	align-self: end;
	margin-top: auto;
	padding-top: 1em;
}
