.container {
	display: flex;

	margin-top: 5rem;
	flex-direction: column;
	align-items: center;

	height: 65vh;
}

.margin {
	margin: 0 10% 0 10%;
	height: 100vh;
	padding: 3em;
}

.icon {
	opacity: 0;
	animation: slideIn 0.8s ease-in-out;
	animation-fill-mode: forwards;
	-webkit-animation-fill-mode: forwards;
}

.title {
	opacity: 0;
	font-size: 6em;
	animation: slideIn 0.8s ease-in-out;
	animation-fill-mode: forwards;
	-webkit-animation-fill-mode: forwards;
	animation-delay: 0.2s;
	-webkit-animation-delay: 0.2s;
}

.section {
	background-color: var(--secondary-background-color);
	width: 100%;
	padding-bottom: 20vh;
}

.heading {
	opacity: 0;
	font-size: 3em;
	text-align: center;
}

.animate,
.card {
	opacity: 0;
}

.animate[data-visible],
.card[data-visible],
.heading[data-visible] {
	animation: slideIn 0.8s ease-in-out;
	animation-fill-mode: forwards;
	-webkit-animation-fill-mode: forwards;
	animation-delay: 0.4s;
	-webkit-animation-delay: 0.4s;
}

.buttons {
	opacity: 0;
	animation: fadeIn 0.3s ease-in-out;

	animation-fill-mode: forwards;
	-webkit-animation-fill-mode: forwards;

	animation-delay: 1s;
	-webkit-animation-delay: 1s;

	display: flex;
	flex-direction: row;
	justify-content: center;
	gap: 1em;
}

.buttons > a > button {
	width: 10em;
}

@keyframes slideIn {
	from {
		transform: translateY(50px);
	}
	to {
		opacity: 1;
		transform: translateY(0);
	}
}

@keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
