.layout {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	align-content: center;

	padding: 1em;
	width: 100%;
}

.layout[data-stretch='true'] {
	align-items: stretch;
}

.layout.centered {
	justify-content: center;
}

.layout > * {
	box-sizing: border-box;
	float: left;
	margin: 0.2em;
}

@media screen and (max-width: 600px) {
	.layout {
		padding: 0;
	}
	.layout > * {
		margin: 1em 0 !important;
		max-width: 100% !important;
	}
}
